<template>
  <div class="mt-5">
    <v-data-table
      dense
      :headers="headerMaster"
      :items="masterDataAudience"
      :loading="loading"
      :items-per-page="paging.size"
      :page.sync="paging.page"
      :server-items-length="paging.total"
      @page-count="paging.pageCount = $event"
      item-key="name">
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'masterDataList',
  props: {
    masterDataAudience: {
      type: Array
    },
    headerMaster: {
      type: Array
    },
    loading: {
      type: Boolean
    },
    paging: {
      type: Object
    }
  },
  data() {
    return {
      masterData: [],
    }
  },
}
</script>

<style>

</style>